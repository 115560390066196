body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tempWeb {
  max-width: 4400px;
  margin: 0 auto;
  text-align: center;
}

.logoheaderImg {
  height: 80%;
  width: auto;
}

.locationImg {
  height: 150px;
  width: auto;
}

.container {
  height: 7vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  max-width: 4400px;
  min-width: 300px;
  min-height: 40px;
  background-color: #7CAED4;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.buttonStyle {
  /* margin:  0px auto !important; */
  color: #FFFFFF;
  width: 200px;
  height: auto;
  background-color: #C80F2D !important;
}

.css-41abqd-MuiTableContainer-root{
  width: 90% !important;
  margin: 0px auto ;
}

.css-11bfvty-MuiToolbar-root-MuiTablePagination-toolbar{
  margin: 0px auto;
  width: 90%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}